import React, { Component } from 'react';
import '../css/Login.css';
import { Auth } from 'aws-amplify';
import logo from '../img/dvi-logo.png';
import Loader from"../components/Loader";
import { Helmet } from 'react-helmet'



class SignInForm extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            signedIn: false,
            loading: false,
            error: false,
            errorstate:"",
            authChallenge:"",
            username:"",
            password: "",
            passwordOld:"",
            passwordNew:"",
            authData:"",
            forgotPasswordCode:"",
            phonenumber:"",
            signUpConfirmCode:"",
            pageTitle:"Login - DVI Energi A/S"
            
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.signIn = this.signIn.bind(this);
        this.confirmSignIn = this.confirmSignIn.bind(this);
    }
    //<Loader type="Circles" color="#green" height={30} width={30}/>
    signIn() {
    	this.setState({loading: true } );
        const { username, password } = this.state;  
        Auth.signIn({
            username: username,
            password: password
        })
        .then((data) => {
          
          this.setState({authData: data})
          if(data.challengeName ==="NEW_PASSWORD_REQUIRED") {
            this.setState({authChallenge: "NEW_PASSWORD_REQUIRED", loading: false, pageTitle:"New Password - DVI Energi A/S"});

          }else {
            this.setState({authChallenge: ""});
          }
        	this.setState({signedIn: true,
                            error: false})
        	this.props.onLogin();
        })
        .catch((err) => { 
          if(err.message ==="Password reset required for the user") {
            this.setState({authChallenge: "FORGOT_PASSWORD", loading: false, pageTitle:"Forgot Password - DVI Energi A/S"});
          }

        	this.setState({loading: false,
                          error: true,
                          errorstate: err.message})
        	console.log(`Error signing in: ${ err.message }`)
        })
        }

      signUp = () => {
        this.setState({loading: true } );
        Auth.signUp(this.state.username, this.state.password)
          .then(data => {
            this.setState({signedIn: false,
                            error: false,
                            loading: false,
                            authChallenge: "CREATE_ACCOUNT_CONFIRM", pageTitle:"Create Account - DVI Energi A/S"})
          })
          .catch(err => { 
            this.setState({loading: false,
                            error: true,
                            errorstate: err.message})
            console.log(`Error signing in: ${ err.message }`)
          });
      }

      signUpConfirm = () => {
        this.setState({loading: true } );
        Auth.confirmSignUp(this.state.username, this.state.signUpConfirmCode)
          .then(data => {
            this.setState({signedIn: false,
                            error: false,
                            loading: false,
                            authChallenge: ""})
          })
          .catch(err => { 
            this.setState({loading: false,
                            error: true,
                            errorstate: err.message})
            console.log(`Error signing in: ${ err.message }`)
          });

      }
        
        submitNewPassword = () => {
          this.setState({loading: true } );
          let user = this.state.authData
          
          Auth.completeNewPassword(user,this.state.passwordNew)
          .then(data => {
            this.setState({signedIn: true,
                            error: false})
            this.props.onLogin();            
          })
          .catch(err => { 
            this.setState({loading: false,
                            error: true,
                            errorstate: err.message})
            console.log(`Error signing in: ${ err.message }`)
          });

        }

        
        
        submitForgotPassword = () => {
          this.setState({loading: true } ); 
          Auth.forgotPasswordSubmit(this.state.username,this.state.forgotPasswordCode, this.state.passwordNew)
          .then(data => {
            this.setState({signedIn: false,
                            error: false,
                            loading: false,
                            authChallenge: ""})
            
          })
          .catch(err => { 
            this.setState({loading: false,
                            error: true,
                            errorstate: err.message})
            console.log(`Error signing in: ${ err.message }`)
          });


        }

        submitConfirmCode = () => {
          this.setState({loading: true } ); 
          Auth.forgotPassword(this.state.username)
          .then(data => {
            this.setState({signedIn: false,
                            error: false,
                            loading: false,
                            authChallenge: "FORGOT_PASSWORD", pageTitle:"Forgot Password - DVI Energi A/S"})
            
          })
          .catch(err => { 
            this.setState({loading: false,
                            error: true,
                            errorstate: err.message})
            console.log(`Error signing in: ${ err.message }`)
          });


        }
        requestSignUpConfirm = () => {
          this.setState({loading: true } ); 
          Auth.resendSignUp(this.state.username)
          .then(data => {
            this.setState({signedIn: false,
                            error: false,
                            loading: false,
                            authChallenge: "CREATE_ACCOUNT_CONFIRM", pageTitle:"Create Account - DVI Energi A/S" })
            
          })
          .catch(err => { 
            this.setState({loading: false,
                            error: true,
                            errorstate: err.message})
            console.log(`Error signing in: ${ err.message }`)
          });

        }


    mailForgotPass = (e) => {
      e.preventDefault();
      this.setState({authChallenge: "MAIL_FORGOT_PASSWORD", pageTitle:"Forgot Password - DVI Energi A/S"})

    }
    createAccount = (e) => {
      e.preventDefault();
      this.setState({authChallenge: "CREATE_ACCOUNT", pageTitle:"Create Account - DVI Energi A/S"})

    }
    backToLogin = (e) => {
      e.preventDefault();
      this.setState({authChallenge: ""})

    }

    resendConfirmCode = (e) => {
      e.preventDefault();
      this.setState({authChallenge: "REQUEST_ACCOUNT_CONFIRM_CODE"})
      
    }
    
    
  
    confirmSignIn() {
        const { username } = this.state;
        Auth.confirmSignIn(username)
        .then(() => console.log('successfully confirmed signed in'))
        .catch((err) => console.log(`Error confirming sign up - ${ err }`))
    }
  
    handleSubmit(e) {
        e.preventDefault();
        if (this.state.authChallenge === "NEW_PASSWORD_REQUIRED") {
          this.submitNewPassword();
        }
        else if (this.state.authChallenge === "FORGOT_PASSWORD") {
          this.submitForgotPassword();
        }
        else if (this.state.authChallenge === "MAIL_FORGOT_PASSWORD") {
          this.submitConfirmCode()
        }
        else if (this.state.authChallenge === "CREATE_ACCOUNT") {
          this.signUp()
        }
        else if (this.state.authChallenge === "CREATE_ACCOUNT_CONFIRM") {
          this.signUpConfirm()
        }
        else if (this.state.authChallenge === "REQUEST_ACCOUNT_CONFIRM_CODE") {
          this.requestSignUpConfirm()
        }
        else {
          this.signIn();
        }
        
    }
    
    handleChange(e) {
        if (e.target.id === 'username') {
          this.setState({
              username: e.target.value.toLowerCase()
          });
        } else if (e.target.id === 'password') {
          this.setState({
              password: e.target.value
          });
        }else if (e.target.id === 'forgotPasswordCode') {
          this.setState({
            forgotPasswordCode: e.target.value
          });
        }else if (e.target.id === 'passwordNew') {
          this.setState({
            passwordNew: e.target.value
          });
        }else if (e.target.id === 'phonenumber') {
          this.setState({
            phonenumber: e.target.value
          });
        }else if (e.target.id === 'signUpConfirmCode') {
          this.setState({
            signUpConfirmCode: e.target.value
          });
        }
    }
    getForgotPassword = (masterStyle2) => {
      let forgPass = ( <div><p></p>
      <p>Insert code and new password!</p>
      Code comes with email.
      <form onSubmit={ this.handleSubmit } style={masterStyle2 }>
        <input onChange={ this.handleChange } autoFocus autoComplete="off" type="text" value={this.state.forgotPasswordCode} id="forgotPasswordCode" className="" placeholder="Confirm code" />
        <input onChange={ this.handleChange } autoComplete="off" type="password" value={this.state.passwordNew} id="passwordNew" className="" placeholder="New password" />
        <input type="submit" className="" value="Reset password" />
        </form></div>);

        return forgPass;

    }
    
    getSignIn = (masterStyle2) => {
      let signIn = ( <form onSubmit={ this.handleSubmit } style={masterStyle2 }>
        <input onChange={ this.handleChange } autoFocus autoComplete="on" type="text" value={this.state.username} id="username" className="fadeIn second" placeholder="Email" />
        <input onChange={ this.handleChange } autoComplete="off" type="password" value={this.state.password} id="password" className="fadeIn third" placeholder="Password" />
        <input type="submit" className="fadeIn fourth" value="Log In" />
        </form>);

        return signIn;
    }
    getNewPasswordRequired = (masterStyle2) => {
      let signIn = ( <div><p></p>
      Please change your password!
      <form onSubmit={ this.handleSubmit } style={masterStyle2 }>
        <input onChange={ this.handleChange } autoFocus autoComplete="off" type="password" value={this.state.passwordNew} id="passwordNew" className="" placeholder="New Password" />
        <input type="submit" className="" value="Change Password" />
        </form></div>);

        return signIn;
    }

    getMailForgotPassword = (masterStyle2) => {
      let signIn = ( <div><p></p>
      Please insert your email!
      <form onSubmit={ this.handleSubmit } style={masterStyle2 }>
        <input onChange={ this.handleChange } autoFocus autoComplete="off" type="text" value={this.state.username} id="username" className="" placeholder="Email" />
        <input type="submit" className="" value="Request code" />
        </form></div>);

        return signIn;
    }
    getMailResendConfirm = (masterStyle2) => {
      let signIn = ( <div><p></p>
      Please insert your email!
      <form onSubmit={ this.handleSubmit } style={masterStyle2 }>
        <input onChange={ this.handleChange } autoFocus autoComplete="off" type="text" value={this.state.username} id="username" className="" placeholder="Email" />
        <input type="submit" className="" value="Request code" />
        </form></div>);

        return signIn;
    }



    getSignUp = (masterStyle2) => {
      let signIn = ( <form onSubmit={ this.handleSubmit } style={masterStyle2 }>
        <input onChange={ this.handleChange } autoFocus autoComplete="on" type="text" value={this.state.username} id="username" className="" placeholder="Email" />

        <input onChange={ this.handleChange } autoComplete="off" type="password" value={this.state.password} id="password" className="" placeholder="Password" />
        <input onChange={ this.handleChange } autoComplete="off" type="password" value={this.state.password} id="password2" className="" placeholder="Repeat password" />
        <input type="submit" className="" value="Create Account" />
        </form>);

        return signIn;
    }

    getSignUpConfirmation = (masterStyle2) => {
      let signIn = ( <div><p></p>
      Confirmation code comes with email!
      <form onSubmit={ this.handleSubmit } style={masterStyle2 }>
        <input onChange={ this.handleChange } autoFocus autoComplete="off" type="text" value={this.state.signUpConfirmCode} id="signUpConfirmCode" className="" placeholder="Confirmation code" />
        <input type="submit" className="" value="Finish Registration" />
        </form></div>);

        return signIn;
    }


    getLoginFooter = () => {
      let footer = (
        <div>
                  <p><a href="/" className="underlineHover " onClick={this.createAccount}>Create Account</a></p>
        		      <a href="/" className="underlineHover " onClick={this.mailForgotPass}>Forgot Password?</a>
        </div>
      );
      return footer;
    }

    getForgotPwFooter = () => {
      let footer = (
        <div>
        		      <a href="/"  className="underlineHover ">Back to login</a>
        </div>
      );
      return footer;
    }
    getCreateAccountFooter = () => {
      let footer = (
        <div> 
                <p><a href="/" className="underlineHover " onClick={this.resendConfirmCode}>Resend confirmation code</a></p>
        		      <a href="/"  className="underlineHover ">Back to login</a>
        </div>
      );
      return footer;
    }


	
    render() { 
    	const { loading, error, errorstate } = this.state;
    	let masterStyle2;
    	let loader;
      let errorout;
    	if (loading) {
    		masterStyle2 = {opacity:"0.2"};
    		loader = <div className="loadingdiv"><div className="loading"><Loader type="Circles" color="#green" height={30} width={30}/></div></div>;
	
    	}
    	if (error) {
    		errorout = <div className="alert alert-danger">
    		{errorstate}
    		  </div>
    	}
    	
    	
    	
        return (
        		
        		<div className="wrapper fadeInDown">
              <Helmet>
                <title>{ this.state.pageTitle }</title>
              </Helmet>
        		  <div id="formContent"  >
        		   <div className="loaddiv">
        		   {loader}
        		    <div className="fadeIn first" style={masterStyle2} >
        		      <img src={logo} id="icon" alt="User Icon" />
        		    </div>
                
                {(() => {
                        switch (this.state.authChallenge) {
                          
                          case "NEW_PASSWORD_REQUIRED": return this.getNewPasswordRequired(masterStyle2);
                          case "FORGOT_PASSWORD": return this.getForgotPassword(masterStyle2);
                          case "MAIL_FORGOT_PASSWORD": return this.getMailForgotPassword(masterStyle2);
                          case "CREATE_ACCOUNT": return this.getSignUp(masterStyle2);
                          case "CREATE_ACCOUNT_CONFIRM": return this.getSignUpConfirmation(masterStyle2);
                          case "REQUEST_ACCOUNT_CONFIRM_CODE": return this.getMailResendConfirm(masterStyle2);
                          default:      return this.getSignIn(masterStyle2);
                        }
                      })()}
                      
        		      {errorout}	   
        		    <div id="formFooter" style={masterStyle2} >
                {(() => {
                        switch (this.state.authChallenge) {
                          case "":   return this.getLoginFooter();
                          case "NEW_PASSWORD_REQUIRED": return "";
                          case "MAIL_FORGOT_PASSWORD": return this.getForgotPwFooter();
                          case "FORGOT_PASSWORD": return "";
                          case "CREATE_ACCOUNT": return this.getCreateAccountFooter();
                          case "CREATE_ACCOUNT_CONFIRM": return "";
                          case "REQUEST_ACCOUNT_CONFIRM_CODE": return "";
                          
                          default:      return this.getLoginFooter();
                        }
                      })()}
        		    
        		      
        		    </div>

        		  </div>
        		  </div>
        		</div>
        );
    	
      
    }
}


export default SignInForm;