import React from "react";
import "../css/Home.css";
import { Helmet } from 'react-helmet'
import { Auth, API } from 'aws-amplify';

import { Redirect } from 'react-router-dom'
import { Button, Row, Container, Col, Modal, Form, Spinner, Table   } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinusSquare, faBell, faCaretRight, faUserMinus } from '@fortawesome/free-solid-svg-icons'

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';



const { SearchBar } = Search;



class Home extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false
		this.state = {
      User:"",
       Heatpumps: [] ,
       modalHPid:"",
       error: false,
       errorstate:"",
       noHPTableText:"Loading...",
       dataLoaded:false,
      Columns: [{
                  dataField: 'HeatpumpID',
                  text: 'Heatpump ID',
                  sort: true,
                }, {
                  dataField: 'HeatpumpName',
                  text: 'Heatpump name',
                  sort: true,
                }, {
                  dataField: 'Address',
                  text: 'Address',
                  sort: true,
                }, {
                  dataField: 'zipcode',
                  text: 'Zip-code',
                  sort: true,
                }, {
                  dataField: 'city',
                  text: 'City',
                  sort: true,
                }, {
                  dataField: '',
                  text: 'Action',
                  formatter: this.GetActionFormat,
                }],
      DefaultSortet: [{
                      dataField: 'HeatpumpName',
                      order: 'desc'
                    }],
                    redirect: false,
                    modalShow: false,
                    modalDeleteShow: false,
                    isAdmin: false,
                    modalAlarmListShow: false,
                    modalAlarmListHeader: "",
                    modalAlarmListHPID: "",
                    MailList:[],
                    mailtoAlarmList:""
    }

  
    };
    GetActionFormat = (cell, row) => {
      return (
          <div>
              <Button style={{margin:"2px"}} onClick={e => this.handleHPRedirect(row)}  variant="outline-success" title="View Heatpump">
                <FontAwesomeIcon icon={faCaretRight} />
              </Button>
              <Button style={{margin:"2px"}} onClick={e => this.modalAlarmListShow(row)} variant="outline-warning" title="Alarm list">
                <FontAwesomeIcon icon={faBell} />
              </Button>
          </div>
      );
  }
  
  addMailtoMailList = (e) => {
    
		let apiName = 'DVIEnergiRESTAPI';
		let path = '/webapp/user/heatpumps/maillist';
		let myInit = { 
      headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` },
      body: {mail: this.state.mailtoAlarmList, heatpumpID: this.state.modalAlarmListHPID}
	  }
		return API.post(apiName, path, myInit);
  }


  getMailList = (e) => {
    
		let apiName = 'DVIEnergiRESTAPI';
		let path = '/webapp/user/heatpumps/maillist';
		let myInit = { 
      headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` },
      queryStringParameters: {  
        HPID: e,
    },
	  }
		return API.get(apiName, path, myInit);
  }
  

  deleteUserFromMailList = (email, HPID) => {
		let apiName = 'DVIEnergiRESTAPI';
		let path = '/webapp/user/heatpumps/maillist';
		let myInit = { 
		  headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` },
		  body: {Mail: email, HPID: HPID}
	  }
		API.del(apiName, path, myInit)
		.then((data) => {
      if(data === true) {
        this.getMailList(this.state.modalAlarmListHPID)
        .then((data) => {
          if (data === false){
            this._isMounted && this.setState({MailList: []})
          }else {
            this._isMounted && this.setState({MailList: data})
          }
        }
        )}	
    })
  }





	
	componentDidMount() {
    this._isMounted = true
    Auth.currentAuthenticatedUser()
		.then((data) => {
      this._isMounted && this.setState({ User: data})
      if (this.state.User.signInUserSession.accessToken.payload['cognito:groups'].includes('DVIAdministrator')) {
        this.setState({isAdmin: true})
      }

      this.getData()
      .then((data) => {
        
        if (data === false){
          this._isMounted && this.setState({noHPTableText: "No Heatpumps", dataLoaded:true})
        }else {
          this._isMounted && this.setState({Heatpumps: data})
        }
        
      })
      Auth.currentUserCredentials()
          .then((data) => {
            this._isMounted && this.setState({UserIdentityID: data.data.IdentityId})
            this._isMounted && this.sendUserID()
          })
	      })
      
  }

  componentWillUnmount() {
    this._isMounted = false
    
  }
  
  sendUserID = (e) => {
  
    let apiName = 'DVIEnergiRESTAPI';
    let path = '/webapp/pushid';
    let myInit = { 
      headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` },
      body: {identityID: this.state.UserIdentityID}
  }
    API.post(apiName, path, myInit)
      
  
      
  }

  
handleSubmit = (e) => {
  
  let apiName = 'DVIEnergiRESTAPI';
  let path = '/webapp/useraddhp';
  let myInit = { 
    headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` },
    body: {"heatpumpID": this.state.modalHPid}
}
  return API.post(apiName, path, myInit)
    .then(response => {


      if (response['HP'] === "added"){
        this.setState({error:false, errorstate:""})
        this.getData()
        .then((data) => {
          this.setState({Heatpumps: data})
      })
        this.hideModal()
      }
      else if (response['HP'] === "already access"){
        this.hideModal()
    }
    else if (response['HP'] === "does not exist"){
      this.setState({error:true, errorstate:"Heatpump does not exist!"})
  }
  else if (response['HP'] === "not allowed"){
    this.setState({error:true, errorstate:"Access to heatpump not allowed"})
}
    })
    .catch(error => {
      console.log(error.response)
    })


}

handleDeleteHPSubmit = (e) => {
  let apiName = 'DVIEnergiRESTAPI';
  let path = '/webapp/admin/heatpump';
  let myInit = { 
    headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` },
    body: {"heatpumpID": this.state.modalHPid}
}
  return API.del(apiName, path, myInit)
    .then(response => {
      
      if (response === "Deleted"){
        this.setState({error:false, errorstate:""})
        this.getData()
        .then((data) => {
          if (data === false){
            this._isMounted && this.setState({noHPTableText: "No Heatpumps", dataLoaded:true})
          }else {
            this._isMounted && this.setState({Heatpumps: data})
          }
          
      })
        this.modalDeleteHPHide()
      }
      else if (response === "No Access"){
        console.log("No Access")
        this.modalDeleteHPHide()
    }
    })
    .catch(error => {
      console.log(error.response)
    })
}

handleDeleteHPAccessSubmit = (e) => {
  let apiName = 'DVIEnergiRESTAPI';
  let path = '/webapp/user/heatpumps';
  let myInit = { 
    headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` },
    body: {"heatpumpID": this.state.modalHPid}
}
  return API.del(apiName, path, myInit)
    .then(response => {
      if (response['HP'] === "Access removed"){
        this.setState({error:false, errorstate:""})
        this.getData()
        .then((data) => {
          if (data === false){
            this._isMounted && this.setState({noHPTableText: "No Heatpumps", dataLoaded:true, Heatpumps:[]})
          }else {
            this._isMounted && this.setState({Heatpumps: data})
          }
      })
        this.modalDeleteHPHide()
      }
      else if (response['HP'] === "already no access"){
        console.log("already no access")
        this.modalDeleteHPHide()
    }
    })
    .catch(error => {
      console.log(error.response)
    })
}




getData = () => { 
    let apiName = 'DVIEnergiRESTAPI';
    let path = '/webapp/dashboard';
    let myInit = { 
      headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` }
  }
    return API.get(apiName, path, myInit);
}


showModal = () => {
  this.setState({modalShow: true})
}
hideModal = () => {
  this.setState({modalShow: false})
}

modalDeleteHPShow = () => {
  this.setState({modalDeleteShow: true})
}
modalDeleteHPHide = () => {
  this.setState({modalDeleteShow: false})
}
modalAlarmListShow = (e) => {
  this.getMailList(e['HeatpumpID'])
  .then((data) => {
    if (data === false){
      this._isMounted && this.setState({MailList: []})
    }else {
      this._isMounted && this.setState({MailList: data})
    }
    
  })

  this.setState({modalAlarmListShow: true, modalAlarmListHeader: e['HeatpumpName'], modalAlarmListHPID:e['HeatpumpID'] })
}
modalAlarmListHide = (e) => {
  this.setState({modalAlarmListShow: false, modalAlarmListHeader: "", modalAlarmListHPID:"", mailtoAlarmList:""})
}

handleChange = (e) => {
  this.setState({
      modalHPid: e.target.value
  })
}

handleHPRedirect = (e) => {
  //console.log(e)
  this.setState({redirect: '/details/' + e['HeatpumpID']})
}

handleMailtoAlarmList = (e) => {
  this.setState({
    mailtoAlarmList: (e.target.value).toLowerCase()
  })
}

renderRedirect = () => {
  if (this.state.redirect) {
    return <Redirect to= {this.state.redirect} />
  }
}

getMails = () => {
	let users = this.state.MailList.map((item, key) =>
	this.getMailView(item,key)
	);
return users
}

HandleMailtoMailList = () => {
this.addMailtoMailList()
.then((data) => {

  this.getMailList(this.state.modalAlarmListHPID)
  .then((data) => {
    if (data === false){
      this._isMounted && this.setState({MailList: [], mailtoAlarmList:""})
    }else {
      this._isMounted && this.setState({MailList: data, mailtoAlarmList:""})
    }
    
  })



})

}


getMailView = (e, key) => {
let view = (
	<tr key={this.state.MailList[key]}>
      <td>{this.state.MailList[key]}</td> 
      <td><Button style={{margin:"2px"}} onClick={e => this.deleteUserFromMailList(this.state.MailList[key], this.state.modalAlarmListHPID)}  variant="outline-danger" title="Remove mail"><FontAwesomeIcon icon={faUserMinus} /></Button></td> 
  </tr>
)
return view
}


  render() {
    const { error, errorstate, dataLoaded} = this.state;
    let noHP;
    let errorout;
    if (error) {
      errorout = <div className="alert alert-danger">
      {errorstate}
        </div>
    }

    if(dataLoaded) {
      noHP = (
        <Button variant="success" disabled>
           
           
  
          
          
          {this.state.noHPTableText}
        </Button>
      );
    } else {
      noHP = (
        <Button variant="success" disabled>
           
            <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
  
          
          
          {this.state.noHPTableText}
        </Button>
      );

    }
    

	  
    return (
    		<div className="container">
          {this.renderRedirect()}
          <Helmet>
          <title>Dashboard - DVI Energi A/S</title>
        </Helmet>
        <p></p>

        <ToolkitProvider
          keyField="HeatpumpID"
          data={ this.state.Heatpumps }
          columns={ this.state.Columns }
          bootstrap4
          search
          
        >
          {
            props => (
              <React.Fragment>
                <Container>
                <Row >
                <Col ><SearchBar { ...props.searchProps } />  </Col> 
                <Col sm={2}>
                  <Button style={{margin:"2px"}} onClick={this.showModal} variant="outline-success" title="Add heatpump"><FontAwesomeIcon icon={faPlus} /></Button>
                  <Button style={{margin:"2px"}} onClick={this.modalDeleteHPShow} variant="outline-danger" title="Delete heatpump"><FontAwesomeIcon icon={faMinusSquare} /></Button>
                  </Col>
                </Row>
                </Container>
                <BootstrapTable { ...props.baseProps  }  noDataIndication={ noHP }  bordered={false} />

              </React.Fragment>
            )
          }
        </ToolkitProvider>











        <Modal show={this.state.modalShow} onHide={this.hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add heatpump</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Heatpump ID</Form.Label>
            <Form.Control type="number" placeholder="Enter heatpump ID" value={this.state.modalHPid} onChange={e => this.handleChange(e)}/>
            <Form.Text className="text-muted">
              You can find the ID on your heatpump controller.
            </Form.Text>
          </Form.Group>

          {errorout}	
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={this.hideModal}>
            Close
          </Button>
          <Button variant="primary" type="button" onClick={this.handleSubmit}>
            Add
          </Button>
        
          
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.modalDeleteShow} onHide={this.modalDeleteHPHide}>
        <Modal.Header closeButton>
          <Modal.Title>Delete heatpump</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Heatpump ID</Form.Label>
            <Form.Control type="number" placeholder="Enter heatpump ID" value={this.state.modalHPid} onChange={e => this.handleChange(e)}/>
            <Form.Text className="text-muted">
              You can find the ID on your heatpump controller.
            </Form.Text>
          </Form.Group>

          {errorout}	
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={this.modalDeleteHPHide}>
            Close
          </Button>
          {this.state.isAdmin ? <Button variant="danger" type="button" onClick={this.handleDeleteHPSubmit}>Delete</Button> : <Button variant="danger" type="button" onClick={this.handleDeleteHPAccessSubmit}>Remove</Button>}
          
          
            
        
          
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.modalAlarmListShow} onHide={this.modalAlarmListHide}>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.modalAlarmListHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container>
          <Form.Group controlId="formBasicEmail">
          <Form.Text className="text-muted">
                Add mail to alarm list.
              </Form.Text>
              <Form.Row>
                <Col>
                  <Form.Control type="email" placeholder="Mail" value={this.state.mailtoAlarmList} onChange={e => this.handleMailtoAlarmList(e)}/>
                </Col>
              <Col xs={2} style={{textAlign: "right"}}>
                <Button style={{margin:"2px"}} onClick={this.HandleMailtoMailList} variant="outline-success" title="Add mail"><FontAwesomeIcon icon={faPlus} /></Button>
              </Col>
                

              </Form.Row>
                

              
              
              
          </Form.Group>
          
            <Table striped bordered hover>
              <thead>
                <tr>
                <th>Email</th>
                <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {this.getMails()}
              </tbody>

            </Table>

          </Container>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={this.modalAlarmListHide}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>


          </div>
    )
  }
}

export default Home;





