import React from "react";
import { Auth } from 'aws-amplify';
import { InputGroup, FormControl} from "react-bootstrap";
import { Helmet } from 'react-helmet'


class User extends React.Component {
    constructor(props) {
		super(props);

		this.state = {
			 userName: "", 
			userGroups: "",
			userMail: "",
			userMailVerified: "",
			userPhone: "",
			userPhoneVerified: ""}
  
    };
	componentDidMount() {
		Auth.currentAuthenticatedUser()
		.then((data) => {
			this.setState({ userName: data.username, 
							userGroups: data.signInUserSession.accessToken.payload["cognito:groups"],
							userMail: data.signInUserSession.idToken.payload.email,
							userMailVerified: data.signInUserSession.idToken.payload.email_verified,
							userPhone: data.signInUserSession.idToken.payload.phone_number,
							userPhoneVerified: data.signInUserSession.idToken.payload.phone_number_verified})
	      })
		  .catch(() => {
			  this.setState({ userName: "", 
			  userGroups: "",
			  userMail: "",
			  userMailVerified: "",
			  userPhone: "",
			  userPhoneVerified: "" })
		})
    }
    

  render() {
  let userMail = this.state.userMail;
  let userMailVerified = this.state.userMailVerified +"";
  let userPhone = this.state.userPhone;
  let userPhoneVerified = this.state.userPhoneVerified +"";
  
  
    return (
    		<div style={{marginTop: "15px"}}>
				<Helmet>
          <title>Profil - DVI Energi A/S</title>
        </Helmet>
				<InputGroup size="sm" className="mb-3">
					<InputGroup.Prepend>
						<InputGroup.Text id="inputGroup-sizing-sm">Mail</InputGroup.Text>
					</InputGroup.Prepend>
					<FormControl aria-label="Small" placeholder={userMail} aria-describedby="inputGroup-sizing-sm" />
				</InputGroup>

				<InputGroup size="sm" className="mb-3">
					<InputGroup.Prepend>
						<InputGroup.Text id="inputGroup-sizing-sm">userMailVerified</InputGroup.Text>
					</InputGroup.Prepend>
					<FormControl aria-label="Small" disabled value={userMailVerified} aria-describedby="inputGroup-sizing-sm" />
				</InputGroup>

				<InputGroup size="sm" className="mb-3">
					<InputGroup.Prepend>
						<InputGroup.Text id="inputGroup-sizing-sm">Phone</InputGroup.Text>
					</InputGroup.Prepend>
					<FormControl aria-label="Small" placeholder={userPhone} aria-describedby="inputGroup-sizing-sm" />
				</InputGroup>

				<InputGroup size="sm" className="mb-3">
					<InputGroup.Prepend>
						<InputGroup.Text id="inputGroup-sizing-sm">userPhoneVerified</InputGroup.Text>
					</InputGroup.Prepend>
					<FormControl aria-label="Small" disabled placeholder={userPhoneVerified} aria-describedby="inputGroup-sizing-sm" />
				</InputGroup>

				
				
          
          </div>
    )
  }
}

export default User;