import React from "react";
import { SocialIcon } from 'react-social-icons';


class Footer extends React.Component {

	
	componentDidMount() {

	}
  
  
  

  render() {
	  

	  
	  
    return (
        
        <footer className="footerShadow page-footer pt-4 fixed-bottom" style={{backgroundColor: "#f8f9fa"}}>
        
        <div >
            <ul className="list-unstyled list-inline text-center">
              <li className="list-inline-item">
                <SocialIcon url="https://www.facebook.com/DVI-Energi-AS-104866300867755/" target="_blank"/>
              </li>
              <li className="list-inline-item">
                
                <SocialIcon url="https://www.linkedin.com/company/17878111" target="_blank"/>
                
              </li>
            </ul>
            </div>
            <div  style={{color:"black"}} className="footer-copyright text-center py-3">© 2020 Copyright: <a style={{fontWeight: "bold"}} href="https://dvienergi.com/" target="_blank" rel="noopener noreferrer">DVIEnergi A/S</a>
                
            </div>
          
          </footer>
    )
  }
}

export default Footer;





