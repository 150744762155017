import React from "react";
import "../css/Home.css";
import { Nav, Navbar, NavLink } from "react-bootstrap";
import Logo from "../components/Logo";
import { LinkContainer } from 'react-router-bootstrap';




class Navigation extends React.Component {
    constructor(props) {
        super(props);
  
        this.state = {
            user: '',   
        };
    }
	componentDidMount() {
       
    }
    

  render() {
    const login = this.props.loggedIn
    

    if (login){
        return (
    		<div>
    			<Navbar bg="light" expand="lg"collapseOnSelect >
                    <div className="container">
    			<Navbar.Brand href="/dash"><Logo size="50px"/></Navbar.Brand>
    			  <Navbar.Toggle aria-controls="basic-navbar-nav" />
    				  <Navbar.Collapse id="basic-navbar-nav "   >
    				    <Nav className="ml-auto">
                        
                        <LinkContainer exact to="/dash"><NavLink >Home</NavLink></LinkContainer>
                        <LinkContainer exact to="/user"><NavLink >Profil</NavLink></LinkContainer>
                        {(this.props.IsAdmin) &&
                          <LinkContainer exact to="/users"><NavLink >User List</NavLink></LinkContainer>
                        }
                        <LinkContainer exact to="/"><NavLink onClick={this.props.onLoggOut}>Logout</NavLink></LinkContainer>
    				      </Nav>
    				      </Navbar.Collapse>
                          </div>
    		      </Navbar>
  
          </div>
    )

    }else {
    return (
    		<div>
    			
          
          </div>
    )}
  }
}

export default Navigation;





