import React from "react";
import "../css/Home.css";
import { Nav, Navbar, Button, ButtonGroup } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';




class MobilNavigation extends React.Component {
    constructor(props) {
        super(props);
  
        this.state = {
            user: '',   
        };
    }
	componentDidMount() {
       
    }
    

  render() {
    const login = this.props.loggedIn
    

    if (login){
        return (
    	
    			<Navbar bg="light" fixed="bottom" style={{display: "block", padding:"0"}}> 				  
    				    <Nav className="justify-content-center d-flex flex-column">
                        <ButtonGroup  size="lg"> 
                            <LinkContainer exact to="/dash"><Button ><i class="fa fa-home"></i></Button></LinkContainer>
                            <LinkContainer exact to="/user"><Button className="" ><i class="fa fa-user"></i></Button></LinkContainer>
                            <LinkContainer exact to="/"><Button onClick={this.props.onLoggOut} className="" ><i class="fa fa-sign-out"></i></Button></LinkContainer>
                        </ButtonGroup>
    				    </Nav>
                          
    		      </Navbar>
             
          
    )

    }else {
    return (
    		<div>
    			
          
          </div>
    )}
  }
}

export default MobilNavigation;





