import React from "react";
import { Auth, API, PubSub } from 'aws-amplify';
import { Button, Row, Col, Spinner, Container, Table, Form    } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopCode, faSync, faChevronCircleLeft, faIcicles, faAngleDown, faAngleUp, faPowerOff, faExclamationTriangle, faTools } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import { Redirect } from 'react-router-dom'
import Timestamp  from 'react-timestamp'
import { Helmet } from 'react-helmet'


class HPDetails extends React.Component {
    constructor(props) {
        
		super(props);

		this.state = {
            id: this.props.match.params.id,
            lastUpdate: 0,
            HPdata:'',
            redirect: false,
            S1EL:0,
            S2EL:0,
            S3EL:0,
            S4EL:0,
            S5EL:0,
            isAdmin: false,
            noAccessRedirect: false,
            showModal: false,
            modalHeader:"",
            modalChangeController: "",
            modalSettingsValue:"",
            modalSettings:"",
            MchangeButton: false,
            S1changeButton: false,
            S2changeButton: false,
            S3changeButton: false,
            S4changeButton: false,
            S5changeButton: false            
      };
      this.subState = null;
            
    };
    


	componentDidMount() {
    Auth.currentAuthenticatedUser()
		.then((data) => {
      this.setState({ User: data})
      if (this.state.User.signInUserSession.accessToken.payload['cognito:groups'].includes('DVIAdministrator')) {
        this.setState({isAdmin: true})
      }
      this.getHPData()
      .then((data) => {
        if (data === false) {
          this.setState({noAccessRedirect: true}) 
        } else {
          this.setState({HPdata: data})
          PubSub.publish('DVI/'+this.state.id+'/change',{ msg: {userConnected: true, getAllData: true } });
          this.subState = PubSub.subscribe('DVI/'+this.state.id+'/state').subscribe({
            next: data => this.updateHPdataState(data['value']),
            error: error => console.error(error),
            close: () => console.log('Done'),
        });
         
        }

        }
        
        )

      })
 
    }

componentWillUnmount(){
  if(this.state.noAccessRedirect) {

  }else{
    this.subState.unsubscribe();
  }
  

}


  updateHPdataState = (data) => {
    let oldData = this.state.HPdata;
   for (let x in data) {
     for (let y in data[x]){
      let MS = x;
       let key = data[x][y]['var'];
       let value = data[x][y]['value'];

       oldData[MS][key] = value;
       
      
      
    
      
     }
    

   }
   this.setState({lastUpdate: new Date()})
   this.setState({HPdata: oldData})
   PubSub.publish('DVI/'+this.state.id+'/change',{ msg: {userConnected: true} });

  }

  pushSoftwareUpdate = () => {
    PubSub.publish('DVI/'+this.state.id+'/change',{ msg: {swupdate: true} });
  }
  pushgetAllData = () => {
    PubSub.publish('DVI/'+this.state.id+'/change',{ msg: {getAllData: true} });
  }

  pushChangeMCX = (msg) => {
    PubSub.publish('DVI/'+this.state.id+'/change',{ msg: {changeMCX: msg} });
  }



  
  getHPData = () => {
      let apiName = 'DVIEnergiRESTAPI';
      let path = '/webapp/hpdata';
      let myInit = { 
        headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` },
        queryStringParameters: {  
          HPID: this.state.id,
      },
        
    }
      return API.get(apiName, path, myInit);
  }
  
  setRedirect = () => {
    this.setState( {redirect: true})
  }
returnSlaveTable = (slaveData) => {
  let out =(<Col key={slaveData}>
<h5>Slave {slaveData[1]}</h5>
<Table striped bordered size="sm">
  <thead>
    <tr>
      <th>Parameter</th>
      <th>Value</th>
    </tr>
  </thead>
  <tbody style={{fontSize:"10px"}}>
    <tr>
      <td>On/Off</td>
      <td>{this.state.HPdata[slaveData].S1}</td>
    </tr>
    <tr>
      <td>Compressor running</td>
      <td>{this.state.HPdata[slaveData].V37}</td>
    </tr>
    {(this.state.isAdmin) &&
       <tr>
       <td>Manual defrost</td>
       <td><Button onClick={() => this.pushChangeMCX({[slaveData]: {9902:1}})}  variant="outline-success" size='sm' title="Start manual defrost"><FontAwesomeIcon   icon={faIcicles} /></Button></td>
     </tr>
    }
    <tr>
      <td>Defrost active</td>
      <td>{this.state.HPdata[slaveData].V48}</td>
    </tr>
    <tr>
      <td>Defrost timer {this.state.HPdata[slaveData].S70} / {this.state.HPdata[slaveData].S71}</td>
      <td>{this.state.HPdata[slaveData].V52} min.</td>
    </tr>
    <tr>
      <td>Defrost actual glide</td>
      <td>{this.state.HPdata[slaveData].V53/10 }K / {this.state.HPdata[slaveData].S80/10}K </td>
    </tr>
    {((this.state.HPdata[slaveData].V50 > "0") || (this.state.HPdata[slaveData].V50 > 0)) &&
       <tr>
       <td>Defrost pause timer</td>
       <td>{this.state.HPdata[slaveData].V50} sec.</td>
     </tr>
    }
    
    {((this.state.HPdata[slaveData].V2 === "1") || (this.state.HPdata[slaveData].V2 === 1)) &&
       <tr>
       <td>Alarm</td>
       <td>{this.state.HPdata[slaveData].V2}</td>
     </tr>
    }
    {((this.state.HPdata[slaveData].V3 === "1") || (this.state.HPdata[slaveData].V3 === 1)) &&
       <tr>
       <td>Warning</td>
       <td>{this.state.HPdata[slaveData].V3}</td>
     </tr>
    }
    {((this.state.HPdata[slaveData].V2 === "1") || (this.state.HPdata[slaveData].V2 === 1)) &&
       <tr>
       <td>Reset Alarm</td>
       <td><Button onClick={() => this.pushChangeMCX({[slaveData]: {1859:2}})}  variant="outline-danger" size='sm' title="Reset Alarm"><FontAwesomeIcon   icon={faExclamationTriangle} /></Button></td>
     </tr>
    }
   
    
    <tr>
      <td>Kondensering Temp</td>
      <td>{this.state.HPdata[slaveData].V30/10 + String.fromCharCode(8451)}</td>
    </tr>
    <tr>
      <td>Evaporating Temp</td>
      <td>{this.state.HPdata[slaveData].V35/10 + String.fromCharCode(8451)}</td>
    </tr>
    <tr>
      <td>Superheat</td>
      <td>{this.state.HPdata[slaveData].V25/10 + String.fromCharCode(8490)}</td>
    </tr>
    <tr>
      <td>Expansion opening</td>
      <td>{this.state.HPdata[slaveData].V40/100}%</td>
    </tr>
    {(this.state.isAdmin) &&
      <tr>
      <td>Ventilator speed</td>
      <td>{this.state.HPdata[slaveData].V21/10} %</td>
    </tr>
   }
    {(this.state.isAdmin) &&
      <tr>
      <td>Software Version</td>
      <td>{this.state.HPdata[slaveData].V74}</td>
    </tr>
   }
    <tr>
      <td>EL actual</td>
      <td>{(Math.round((this.state.HPdata[slaveData].V41/10000) * 100) / 100).toFixed(2)}kW</td>
    </tr>
    <tr>
      <td>EL total</td>
      <td>{this.state.HPdata[slaveData].V42/10}kWh</td>
    </tr>
    {((this.state.HPdata[slaveData].S93 === "1") || (this.state.HPdata[slaveData].S93 === 1)) &&
      <tr>
      <td>Energymeter flow</td>
      <td>{this.state.HPdata[slaveData].V56}l/h</td>
    </tr>
    }
    {((this.state.HPdata[slaveData].S93 === "1") || (this.state.HPdata[slaveData].S93 === 1)) &&
      <tr>
      <td>Energymeter Temp in</td>
      <td>{this.state.HPdata[slaveData].V59/100 + String.fromCharCode(8451)}</td>
    </tr>
    }
    {((this.state.HPdata[slaveData].S93 === "1") || (this.state.HPdata[slaveData].S93 === 1)) &&
      <tr>
      <td>Energymeter Temp out</td>
      <td>{this.state.HPdata[slaveData].V58/100 + String.fromCharCode(8451)}</td>
    </tr>
    }
    {((this.state.HPdata[slaveData].S93 === "1") || (this.state.HPdata[slaveData].S93 === 1)) &&
      <tr>
      <td>Energymeter kW actual</td>
      <td>{this.state.HPdata[slaveData].V57/100}kW</td>
    </tr>
    }
    {((this.state.HPdata[slaveData].S93 === "1") || (this.state.HPdata[slaveData].S93 === 1)) &&
      <tr>
      <td>Energymeter mW total</td>
      <td>{this.state.HPdata[slaveData].V60/100}mWh</td>
    </tr>
    }
    {(((this.state.HPdata[slaveData].S93 === "1")&&(this.state.HPdata[slaveData].V37 === "1")) || ((this.state.HPdata[slaveData].S93 === 1)&&(this.state.HPdata[slaveData].V37 === 1))) &&
      <tr>
      <td>COP</td>
      <td>{(Math.round((this.state.HPdata[slaveData].V57/100)/(this.state.HPdata[slaveData].V41/10000) * 100) / 100).toFixed(2)}</td>
      </tr>
    
    } 
    {((this.state.HPdata[slaveData].S93 === "1") || (this.state.HPdata[slaveData].S93 === 1)) &&
      <tr>
      <td>SCOP</td>
      <td>{(Math.round((this.state.HPdata[slaveData].V60*10)/(this.state.HPdata[slaveData].V42/10) * 100) / 100).toFixed(2)}</td>
    </tr>
    } 
    
  </tbody>
</Table>

</Col>

  )


return out
}


returnSlaveTables = () => {
  let out, S1, S2, S3, S4, S5
  if(this.state.HPdata !== ''){

    if(Object.keys(this.state.HPdata.S1).length !== 0) {
      S1 = this.returnSlaveTable(("S1"))
    }
    if(Object.keys(this.state.HPdata.S2).length !== 0) {
      S2 = this.returnSlaveTable(("S2"))
    }
    if(Object.keys(this.state.HPdata.S3).length !== 0) {
      S3 = this.returnSlaveTable(("S3"))
    }
    if(Object.keys(this.state.HPdata.S4).length !== 0) {
      S4 = this.returnSlaveTable(("S4"))
    }
    if(Object.keys(this.state.HPdata.S5).length !== 0) {
      S5 = this.returnSlaveTable(("S5"))
    }
   
    out = [S1,S2,S3,S4,S5]
    
    return out
  }

}
returnMasterData = () => {
  let SCOPtotal = (this.state.HPdata.S1.V60*10 +this.state.HPdata.S2.V60*10+this.state.HPdata.S3.V60*10+this.state.HPdata.S4.V60*10+this.state.HPdata.S5.V60*10)/(this.state.HPdata.S1.V42/10 + this.state.HPdata.S2.V42/10 + this.state.HPdata.S3.V42/10+ this.state.HPdata.S4.V42/10 +this.state.HPdata.S5.V42/10)
  let out =(<Col key="M">
<h5>Data</h5>
<Table striped bordered size="sm">
  <thead>
    <tr>
      <th>Parameter</th>
      <th>Value</th>
    </tr>
  </thead>
  <tbody style={{fontSize:"10px"}}>
    <tr>
      <td>On/Off</td>
      <td>{this.state.HPdata.M.S1}</td>
    </tr>
    {((this.state.HPdata.M.S46 === "1") || (this.state.HPdata.M.S46 === 1)) &&
     <tr>
     <td>PI Regulator</td>
     <td>{this.state.HPdata.M.V71}</td>
   </tr>
    }
    
    <tr>
      <td>CH control Temp</td>
      <td>{this.state.HPdata.M.V51/10 + String.fromCharCode(8451)}</td>
    </tr>
    {((this.state.HPdata.M.V35 === "1") || (this.state.HPdata.M.V35 === 1)) &&
      <tr>
      <td>Alarm</td>
      <td>{this.state.HPdata.M.V35}</td>
    </tr>
    }
    {((this.state.HPdata.M.V36 === "1") || (this.state.HPdata.M.V36 === 1)) &&
      <tr>
      <td>Warning</td>
      <td>{this.state.HPdata.M.V36}</td>
    </tr>
    
    }
    <tr>
      <td>Started moduler</td>
      <td>{this.state.HPdata.M.V38}</td>
    </tr>
    {((this.state.HPdata.M.S53 === "1") || (this.state.HPdata.M.S53 === 1)) &&
      <tr>
        <td>Energymeter flow</td>
        <td>{this.state.HPdata.M.V20}l/h</td>
      </tr>
    }
    {((this.state.HPdata.M.S53 === "1") || (this.state.HPdata.M.S53 === 1)) &&
      <tr>
      <td>Energymeter Temp in</td>
      <td>{this.state.HPdata.M.V21/100 + String.fromCharCode(8451)}</td>
    </tr>
    }
    {((this.state.HPdata.M.S53 === "1") || (this.state.HPdata.M.S53 === 1)) &&
      <tr>
      <td>Energymeter Temp out</td>
      <td>{this.state.HPdata.M.V22/100 + String.fromCharCode(8451)}</td>
    </tr>
    }
    {((this.state.HPdata.M.S53 === "1") || (this.state.HPdata.M.S53 === 1)) &&
      <tr>
      <td>Energymeter kW actual</td>
      <td>{this.state.HPdata.M.V23/100}kW</td>
    </tr>
    }
    {((this.state.HPdata.M.S53 === "1") || (this.state.HPdata.M.S53 === 1)) &&
      <tr>
      <td>Energymeter mW total</td>
      <td>{this.state.HPdata.M.V24/100}mWh</td>
    </tr>
    }
    
    {((this.state.HPdata.M.S14 === "1") || (this.state.HPdata.M.S14 === 1)) &&
      <tr>
        <td>Tilskudsvarme Temp</td>
        <td>{this.state.HPdata.M.SENS7/10 + String.fromCharCode(8451)}</td>
      </tr>
      
    }
     {((this.state.HPdata.M.S14 === "1") || (this.state.HPdata.M.S14 === 1)) &&
      
      <tr>
        <td>Tilskudsvarme opening</td>
        <td>{this.state.HPdata.M.V07/10}%</td>
      </tr>
    }
    
    <tr>
      <td>Outside Temp</td>
      <td>{this.state.HPdata.M.SENS6/10 + String.fromCharCode(8451)}</td>
    </tr>
    <tr>
      <td>HP forward / CH Temp</td>
      <td>{this.state.HPdata.M.SENS8/10 + String.fromCharCode(8451)}</td>
    </tr>
    {((this.state.HPdata.M.S23 === "1") || (this.state.HPdata.M.S23 === 1)) &&
      <tr>
        <td>HW Temp</td>
        <td>{this.state.HPdata.M.SENS9/10 + String.fromCharCode(8451)}</td>
      </tr>
    }
    {((this.state.HPdata.S1.S93 === "1") || (this.state.HPdata.S1.S93 === 1)) &&
      <tr>
      <td>SCOP Total</td>
      <td>{(Math.round(SCOPtotal * 100) / 100).toFixed(2)}</td>
    </tr>
    }
    {(this.state.isAdmin) &&
      <tr>
      <td>Software Version</td>
      <td>{this.state.HPdata.M.V59}</td>
    </tr>
   }
    
    
    
    
    
  </tbody>
</Table>

</Col>

  )


return out
}

changeSettingsButtonCRTL = (e) => {
this.setState({modalChangeController: e.target.value})
if(e.target.value ==="M") {
this.setState({MchangeButton: true, S1changeButton: false, S2changeButton: false, S3changeButton: false, S4changeButton: false, S5changeButton: false})
}
if(e.target.value ==="S1") {
this.setState({MchangeButton: false, S1changeButton: true, S2changeButton: false, S3changeButton: false, S4changeButton: false, S5changeButton: false})
}
if(e.target.value ==="S2") {
this.setState({MchangeButton: false, S1changeButton: false, S2changeButton: true, S3changeButton: false, S4changeButton: false, S5changeButton: false})
}
if(e.target.value ==="S3") {
this.setState({MchangeButton: false, S1changeButton: false, S2changeButton: false, S3changeButton: true, S4changeButton: false, S5changeButton: false})
}
if(e.target.value ==="S4") {
  this.setState({MchangeButton: false, S1changeButton: false, S2changeButton: false, S3changeButton: false, S4changeButton: true, S5changeButton: false})
}
if(e.target.value ==="S5") {
  this.setState({MchangeButton: false, S1changeButton: false, S2changeButton: false, S3changeButton: false, S4changeButton: false, S5changeButton: true})
}

}

modalBody = () => {
let inputs, items, M, S1, S2, S3, S4, S5

if(this.state.HPdata !== ''){
    M = <Button style={{margin:"2px"}} onClick={e => this.changeSettingsButtonCRTL(e, "value")} key="M" value="M" variant="outline-success" size='sm' title="Master Controller" active={this.state.MchangeButton}  >M</Button>
  if(Object.keys(this.state.HPdata.S1).length !== 0) {
    S1 = <Button style={{margin:"2px"}} onClick={e => this.changeSettingsButtonCRTL(e, "value")} key="S1" value="S1" variant="outline-success" size='sm' title="Slave 1 Controller" active={this.state.S1changeButton} >S1</Button>
  }
  if(Object.keys(this.state.HPdata.S2).length !== 0) {
    S2 = <Button style={{margin:"2px"}} onClick={e => this.changeSettingsButtonCRTL(e, "value")} key="S2" value="S2" variant="outline-success" size='sm' title="Slave 2 Controller" active={this.state.S2changeButton} >S2</Button>
  }
  if(Object.keys(this.state.HPdata.S3).length !== 0) {
    S3 = <Button style={{margin:"2px"}} onClick={e => this.changeSettingsButtonCRTL(e, "value")} key="S3" value="S3" variant="outline-success" size='sm' title="Slave 3 Controller" active={this.state.S3changeButton} >S3</Button>
  }
  if(Object.keys(this.state.HPdata.S4).length !== 0) {
    S4 = <Button style={{margin:"2px"}} onClick={e => this.changeSettingsButtonCRTL(e, "value")} key="S4" value="S4" variant="outline-success" size='sm' title="Slave 4 Controller" active={this.state.S4changeButton} >S4</Button>
  }
  if(Object.keys(this.state.HPdata.S5).length !== 0) {
    S5 = <Button style={{margin:"2px"}} onClick={e => this.changeSettingsButtonCRTL(e, "value")} key="S5" value="S5" variant="outline-success" size='sm' title="Slave 5 Controller" active={this.state.S5changeButton} >S5</Button>
  }
 
  items = [M,S1,S2,S3,S4,S5]

}


if(this.state.modalChangeController !== "") {
  inputs = (<Form.Group as={Col} >
    <Form.Label column >
      Modbus ID
    </Form.Label>
      <Col>
      <Form.Control placeholder="Modbus ID" type="number" onChange={this.changeModalSetting} value={this.state.modalSettings}/>
      </Col>
      <Form.Label column >
      Value
    </Form.Label>
      <Col>
      <Form.Control placeholder="Value" type="number" onChange={this.changeModalValue} value={this.state.modalSettingsValue}/>
      </Col>
    </Form.Group>)

}
else {
  inputs = ("")
}


let out = (
  <div>
    <Form>
    <Form.Group as={Col} controlId="formHorizontalEmail">
      <Form.Label column >
          Choose Controller
        </Form.Label>
      <Col>
        {items}
      </Col>
    </Form.Group>
    {inputs}
    </Form>
</div>


)


return out
}



returnMasterSettings = () => {
  let out =(<Col key="MSettings">
<h5>Settings</h5>
<Table striped bordered size="sm">
  <thead>
    <tr>
      <th>Parameter</th>
      <th>Value</th>
      <th style={{height: '20px', width: '20px'}}>Control</th>
    </tr>
  </thead>
  <tbody style={{fontSize:"10px"}}>
    <tr >
      <td>On/Off</td>
      <td>{this.state.HPdata.M.S1}</td>
      {((this.state.HPdata.M.S1 === "0") || (this.state.HPdata.M.S1 === 0)) &&
      <td ><Button onClick={() => this.pushChangeMCX({M: {3001:+this.state.HPdata.M.S1 + 1}})}  variant="outline-success" size='sm' title="On"><FontAwesomeIcon   icon={faPowerOff} /></Button></td>
      }
      {((this.state.HPdata.M.S1 === "1") || (this.state.HPdata.M.S1 === 1)) &&
      <td ><Button onClick={() => this.pushChangeMCX({M: {3001:+this.state.HPdata.M.S1 - 1}})}  variant="outline-danger" size='sm' title="Off"><FontAwesomeIcon   icon={faPowerOff} /></Button></td>
      }
      </tr>
    {((this.state.HPdata.M.S2 === "0") || (this.state.HPdata.M.S2 === 0)) &&
      <tr>
      <td>CH Temp</td>
      <td>{this.state.HPdata.M.S3 + String.fromCharCode(8451)}</td>
      <td xs={1} style={{whiteSpace: "nowrap"}}>
      <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3017:+this.state.HPdata.M.S3 - 1}})} variant="outline-success" size='sm' title="-1"><FontAwesomeIcon  icon={faAngleDown} /></Button>
      <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3017:+this.state.HPdata.M.S3 + 1}})} variant="outline-success" size='sm' title="+1"><FontAwesomeIcon  icon={faAngleUp} /></Button></td>
    </tr>  
    
}
{(((this.state.HPdata.M.S2 === "0")&& (this.state.isAdmin)) || ((this.state.HPdata.M.S2 === 0)&& (this.state.isAdmin))) &&
      <tr>
      <td>CH Temp dif</td>
      <td>{this.state.HPdata.M.S4/10 + String.fromCharCode(8490)}</td>
      <td xs={1} style={{whiteSpace: "nowrap"}}>
      <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3018:+this.state.HPdata.M.S4 - 1}})} variant="outline-success" size='sm' title="-1"><FontAwesomeIcon  icon={faAngleDown} /></Button>
      <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3018:+this.state.HPdata.M.S4 + 1}})} variant="outline-success" size='sm' title="+1"><FontAwesomeIcon  icon={faAngleUp} /></Button></td>
    </tr>  
   }
    
    {((this.state.HPdata.M.S2 === "1") || (this.state.HPdata.M.S2 === 1)) &&
      <tr>
      <td>Curve Temp at -12{String.fromCharCode(8451)}</td>
      <td>{this.state.HPdata.M.S41 + String.fromCharCode(8451)}</td>
      <td xs={1} style={{whiteSpace: "nowrap"}}>
        <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3055:+this.state.HPdata.M.S41  -1}})} variant="outline-success" size='sm' title="-1"><FontAwesomeIcon  icon={faAngleDown} /></Button>
        <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3055:+this.state.HPdata.M.S41 + 1}})} variant="outline-success" size='sm' title="+1"><FontAwesomeIcon  icon={faAngleUp} /></Button>
      </td>
    </tr>   
    }
    {((this.state.HPdata.M.S2 === "1") || (this.state.HPdata.M.S2 === 1)) &&
      <tr>
      <td>Curve Temp at 20{String.fromCharCode(8451)}</td>
      <td>{this.state.HPdata.M.S42 + String.fromCharCode(8451)}</td>
      <td xs={1} style={{whiteSpace: "nowrap"}}>
        <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3056:+this.state.HPdata.M.S42 - 1}})} variant="outline-success" size='sm' title="-1"><FontAwesomeIcon  icon={faAngleDown} /></Button>
        <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3056:+this.state.HPdata.M.S42 + 1}})} variant="outline-success" size='sm' title="+1"><FontAwesomeIcon  icon={faAngleUp} /></Button>
      </td>
    </tr>   
    }
    {((this.state.HPdata.M.S2 === "1") || (this.state.HPdata.M.S2 === 1)) &&
      <tr>
      <td>Curve actual Temp</td>
      <td>{this.state.HPdata.M.V06 + String.fromCharCode(8451)}</td>
      <td></td>
    </tr>   
    }
    {((this.state.HPdata.M.S23 === "1") || (this.state.HPdata.M.S23 === 1)) &&
      <tr>
        <td>HW Temp</td>
        <td>{this.state.HPdata.M.S29 + String.fromCharCode(8451)}</td>
        <td xs={1} style={{whiteSpace: "nowrap"}}>
        <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3043:+this.state.HPdata.M.S29 - 1}})} variant="outline-success" size='sm' title="-1"><FontAwesomeIcon  icon={faAngleDown} /></Button>
        <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3043:+this.state.HPdata.M.S29 + 1}})} variant="outline-success" size='sm' title="+1"><FontAwesomeIcon  icon={faAngleUp} /></Button>
        </td>
      </tr>
    }
   
      <tr>
        <td>Tilskudsvarme</td>
        <td>{this.state.HPdata.M.S14}</td>
        {((this.state.HPdata.M.S14 === "0") || (this.state.HPdata.M.S14 === 0)) &&
      <td ><Button onClick={() => this.pushChangeMCX({M: {3028:+this.state.HPdata.M.S14 + 1}})}  variant="outline-success" size='sm' title="On"><FontAwesomeIcon   icon={faPowerOff} /></Button></td>
      }
      {((this.state.HPdata.M.S14 === "1") || (this.state.HPdata.M.S14 === 1)) &&
      <td ><Button onClick={() => this.pushChangeMCX({M: {3028:+this.state.HPdata.M.S14 - 1}})}  variant="outline-danger" size='sm' title="Off"><FontAwesomeIcon   icon={faPowerOff} /></Button></td>
      }
      </tr>
      {(((this.state.HPdata.M.S14 === "1")&& (this.state.isAdmin)) || ((this.state.HPdata.M.S14 === 1)&& (this.state.isAdmin))) &&
      <tr>
        <td>Tilskudsvarme dif</td>
        <td>{this.state.HPdata.M.S21 + String.fromCharCode(8490)}</td>
        <td xs={1} style={{whiteSpace: "nowrap"}}>
        <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3035:+this.state.HPdata.M.S21 - 1}})} variant="outline-success" size='sm' title="-1"><FontAwesomeIcon  icon={faAngleDown} /></Button>
        <Button style={{margin:"2px"}} onClick={() => this.pushChangeMCX({M: {3035:+this.state.HPdata.M.S21 + 1}})} variant="outline-success" size='sm' title="+1"><FontAwesomeIcon  icon={faAngleUp} /></Button>
        </td>
      </tr>
    }

     
  </tbody>
</Table>

</Col>

  )


return out
}


handleClose = (e) => {
  this.setState({modalSettingsValue:"",modalSettings: "null", showModal: false, modalChangeController: "", MchangeButton: false, S1changeButton: false, S2changeButton: false, S3changeButton: false, S4changeButton: false, S5changeButton: false})}

handleShow = (e) => {
  this.setState({showModal: true})
}
changeModalValue = (e) => {
  this.setState({modalSettingsValue: e.target.value})
}
changeModalSetting = (e) => {
  this.setState({modalSettings: e.target.value})
}

handleChangeSubmit = () => {
  this.pushChangeMCX({[this.state.modalChangeController]: {[this.state.modalSettings]:+[this.state.modalSettingsValue][0]}})
  this.setState({modalSettingsValue:"",modalSettings: ""})
}


renderRedirect = () => {
  if ((this.state.noAccessRedirect) || (this.state.redirect)) {
    return <Redirect to="/dash" />
  }
}
  render() {
    
    if(this.state.HPdata === ''){
        return (
          <div style={{marginTop: "15px"}}>
            {this.renderRedirect()}
            <Row>
              <Col xs={1}>
                <Button onClick={this.setRedirect} variant="outline-success" title="back"><FontAwesomeIcon icon={faChevronCircleLeft} /></Button>
              </Col>
            </Row>
          <Helmet>
            <title>Details - DVI Energi A/S</title>
          </Helmet>
          <Button variant="success" disabled>
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
    />
    Loading...
  </Button>
              
            </div>
      )


    }
    else {
      return (
        <div style={{marginTop: "15px"}}>
          {this.renderRedirect()}
         



          {this.renderRedirect()}
          <Row>
          <Col xs={1}>
              <Button onClick={this.setRedirect} variant="outline-success" title="back"><FontAwesomeIcon icon={faChevronCircleLeft} /></Button>
            </Col>
          <Col fluid="true"></Col>
            <Col style={{textAlign: "right"}} >
            <p>Last update <Timestamp date={this.state.lastUpdate} options={{ twentyFourHour: true }} /></p>
            </Col>
          </Row>
          <Row>
            <Col fluid="true"></Col>
            {(this.state.isAdmin) &&
              <Col style={{whiteSpace: "nowrap", textAlign: "right"}}>
              <Button style={{margin:"2px"}} onClick={this.handleShow} variant="outline-secondary" title="Change settings"><FontAwesomeIcon icon={faTools} /></Button>
              <Button style={{margin:"2px"}} onClick={this.pushgetAllData} variant="outline-success" title="Refresh data"><FontAwesomeIcon icon={faSync} /></Button>
              <Button style={{margin:"2px"}} onClick={this.pushSoftwareUpdate} variant="outline-info" title="Software update"><FontAwesomeIcon icon={faLaptopCode} /></Button>
              </Col>
            }
            
          </Row>
        <Helmet>
          <title>Details - DVI Energi A/S</title>
        </Helmet>
        <Container>
        <Row>
      {this.returnMasterData()}
      {this.returnMasterSettings()}
            
          </Row>
          <Row>
      {this.returnSlaveTables()}
            
          </Row>
        </Container>
        
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Change settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.modalBody()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="danger" type="button" onClick={this.handleChangeSubmit}>
              Send
            </Button>
          
            
          </Modal.Footer>
      </Modal>
          
          </div>
    )
    
    }
    
    
  }
  
}

export default HPDetails;