import React, { Component } from 'react';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
export default class App extends Component {
  //other logic
    render() {
	 return(
	  <Loader
	     type="Puff"
	     color="#8fba1c"
	     height={50}
	     width={50}
	     timeout={5000} //3 secs

	  />
	 );
    }
 }