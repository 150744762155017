import React from "react";
import {Switch, Route, Redirect } from "react-router-dom";


import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import Home from './Home';
import User from './User';
import UserList from './UserList';
import HPDetails from './HPDetails';

class Routers extends React.Component {
    constructor(props) {
        super(props);
  
        this.state = {
            user: '',   
        };
    }
	componentDidMount() {
    }
    
  render() {
    const login = this.props.loggedIn;
    

        return (
    		<div>
                    <Switch>
                        <Route exact path="/dash" render={() => (
                                                                login ? (
                                                                    <Home user={this.props.user} />
                                                                ) : (
                                                                    <Redirect to="/login"/>
                                                                )
                                                                )}/>
                        <Route exact path="/details/:id"  render={(props) => (
                                                                login ? (
                                                                    <HPDetails {...props}/>
                                                                ) : (
                                                                    <Redirect to="/login"/>
                                                                )
                                                                )}/>
                        <Route exact path="/user" render={() => (
                                                                login ? (
                                                                    <User user={this.props.user}/>
                                                                ) : (
                                                                    <Redirect to="/login"/>
                                                                )
                                                                )}/>
                        <Route exact path="/users" render={() => (
                                                                login ? (
                                                                    <UserList/>
                                                                ) : (
                                                                    <Redirect to="/login"/>
                                                                )
                                                                )}/>                                        
                        <Route exact path="/login" render={() => (
                                                                login ? (
                                                                    <Redirect to="/dash"/>
                                                                ) : (
                                                                    <SignInForm onLogin={this.props.onLogin} />
                                                                )
                                                                )}/>
                        <Route exact path="/signup" render={() => (
                                                                login ? (
                                                                    <Redirect to="/dash"/>
                                                                ) : (
                                                                    <SignUpForm onLogin={this.props.onLogin} />
                                                                )
                                                                )}/>
                        <Route render={() => (
                                                                login ? (
                                                                    <Redirect to="/dash"/>
                                                                ) : (
                                                                    <Redirect to="/login"/>
                                                                )
                                                                )}/>
                    </Switch>
          </div>
    )

    
  }
}

export default Routers;