import React, { Component } from 'react';
import '../css/Login.css';
import { Auth } from 'aws-amplify';
import logo from '../img/dvi-logo.png';
import {Link} from "react-router-dom";


class SignUpForm extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            user: '',
            password: '',
            signedIn: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.signIn = this.signIn.bind(this);
        this.confirmSignIn = this.confirmSignIn.bind(this);
    }
  
    signIn() {
        const { username, password } = this.state;  
        Auth.signIn({
            username: username,
            password: password
        })
        .then( function() {
        	console.log('successfully signed in')       	
        }
        )
        .catch((err) => console.log(`Error signing in: ${ err }`))
    }
  
    confirmSignIn() {
        const { username } = this.state;
        Auth.confirmSignIn(username)
        .then(() => console.log('successfully confirmed signed in'))
        .catch((err) => console.log(`Error confirming sign up - ${ err }`))
    }
  
    handleSubmit(e) {
        e.preventDefault();

        this.signIn();
        
        this.setState({
            username: '',
            password: '',
            signedIn: false
        });
        e.target.reset();
        const { signedIn } = this.state;
        console.log(signedIn)
    }
  
    handleChange(e) {
        if (e.target.id === 'username') {
          this.setState({
              username: e.target.value
          });
        } else if (e.target.id === 'password') {
          this.setState({
              password: e.target.value
          });
        }
    }
  
    render() {
        return (
        		<div className="wrapper fadeInDown">
        		  <div id="formContent">
        		    
        		    <div className="fadeIn first">
        		      <img src={logo} id="icon" alt="User Icon" />
        		    </div>

        		  
        		    <form onSubmit={ this.handleSubmit }>
        		      <input onChange={ this.handleChange } type="text" id="username" className="fadeIn second" placeholder="Email" />
        		      <input onChange={ this.handleChange } type="password" id="password" className="fadeIn third" placeholder="Password" />
        		      <input type="submit" className="fadeIn fourth" value="Register" />
        		    </form>

        		   
        		    <div id="formFooter">
        		    <Link className="underlineHover" to="/login">Login</Link>
        		      
        		      
        		    </div>

        		  </div>
        		</div>
        );
      
    }
}

export default SignUpForm;