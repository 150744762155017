import React, {Component} from "react";
import "./css/App.css";
import Amplify from 'aws-amplify';
import aws_config from './aws-config.js';

import { Auth } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

import {BrowserView,MobileView} from "react-device-detect";
import Logo from "./components/Logo";
import {BrowserRouter } from "react-router-dom";

import Footer from "./containers/Footer";
import Routers from "./containers/Router";
import Navigation from "./containers/Nav";
import MobilNavigation from "./containers/MobileNav";





/*
import { Logger } from 'aws-amplify';
Amplify.Logger.LOG_LEVEL = 'DEBUG';
window.LOG_LEVEL = 'DEBUG';
const logger = new Logger('foo', 'INFO');
*/


Amplify.configure(aws_config);
Amplify.addPluggable(new AWSIoTProvider({
	aws_pubsub_region: 'eu-central-1',
	aws_pubsub_endpoint: 'wss://a3t6titw0mhpei-ats.iot.eu-central-1.amazonaws.com/mqtt',
  }));
 // Amplify.Logger.LOG_LEVEL = 'DEBUG';


class App extends Component {
	constructor(props){
		super(props);
		this.state = {
				loaded: false,
			    isAuthenticated: true,
				user:"",
				redirect:"/login",
				refresh: false,
				isAdmin: false,
			    };
	}
	
	componentDidMount() {
		this.authenticate()
	  }


	
	authenticate = () => {
	    Auth.currentAuthenticatedUser()
	      .then((data) => {
			this.setState({ loaded: true, isAuthenticated: true, user: data });
			if (data.signInUserSession.accessToken.payload['cognito:groups'].includes('DVIAdministrator')) {
				this.setState({isAdmin: true})
			  }
				
	      })
		  .catch(() => {
			  this.setState({ loaded: false, isAuthenticated: false , redirect: "/login" })
		})
		  
	  }

	  signOut = () => {
		Auth.signOut()
		.then(() => {
			this.setState({ loaded: false, isAuthenticated: false, user: false  })
	      })
		  .catch(() => {
		})
      }

	


	
  render() {
	  let mobileLogo;

		if (this.state.isAuthenticated) {
			mobileLogo = (
			<div className="mobileLogo footerShadow">
			<Logo size="100px"/>
			</div>);
		}


		
    return (
    		
	<div className="App">
		<BrowserRouter >
		<BrowserView>
						<Navigation loggedIn={this.state.isAuthenticated} IsAdmin={this.state.isAdmin} onLoggOut={this.signOut}/>
						</BrowserView>
						<MobileView>
							{mobileLogo}
						</MobileView>

						<div className="container" style={{paddingBottom:"150px"}}>
						<Routers loggedIn={this.state.isAuthenticated} onLogin={this.authenticate} user={this.state.user}/>

						</div>
						<MobileView>
						<MobilNavigation loggedIn={this.state.isAuthenticated} onLoggOut={this.signOut}/>
						</MobileView>
						<BrowserView>
						<Footer />
						</BrowserView>
		</BrowserRouter >
	</div>
	);
	
  }
}

export default App