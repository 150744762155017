import React from "react";
import { Auth, API } from 'aws-amplify';
//import { InputGroup, FormControl} from "react-bootstrap";
import { Helmet } from 'react-helmet'
import {  faMinusSquare, faUserShield } from '@fortawesome/free-solid-svg-icons'
import { Button, Container, Table   } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class UserList extends React.Component {
    constructor(props) {
		super(props);
		this._isMounted = false;

		this.state = {
			User: "",			
			UserList:[]
		}
  
    };
	componentDidMount() {
		this._isMounted = true
		Auth.currentAuthenticatedUser()
			.then((data) => {
		  this._isMounted && this.setState({ User: data});
		  this.getUserList()
		  .then((data) => {
			this._isMounted && this.setState({UserList: data});
			  
		  })
		})	
		
	  }

	componentWillUnmount() {
		this._isMounted = false
		
	  }

	  getUserList = () => { 
		let apiName = 'DVIEnergiRESTAPI';
		let path = '/webapp/admin/user/list';
		let myInit = { 
		  headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` }
	  }
		return API.get(apiName, path, myInit);
	}

  
addUsertoGroup = (e) => {
		let apiName = 'DVIEnergiRESTAPI';
		let path = '/webapp/admin/user/group';
		let myInit = { 
		  headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` },
		  body: {Username: e['username']}
	  }
		API.post(apiName, path, myInit)
		.then((data) => {
			this.getUserList()
			.then((data) => {
			  this._isMounted && this.setState({UserList: data});
			})
		})		
	  }

removeUserfromGroup = (e) => {
		let apiName = 'DVIEnergiRESTAPI';
		let path = '/webapp/admin/user/group';
		let myInit = { 
		  headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` },
		  body: {Username: e['username']}
	  }
		API.del(apiName, path, myInit)
		.then((data) => {
			this.getUserList()
			.then((data) => {
			  this._isMounted && this.setState({UserList: data});
			})
		})		
	  }

deleteUser = (e) => {
		let apiName = 'DVIEnergiRESTAPI';
		let path = '/webapp/admin/user';
		let myInit = { 
		  headers: { Authorization: `Bearer ${this.state.User.signInUserSession.idToken.jwtToken}` },
		  body: {Username: e['username']}
	  }
		API.del(apiName, path, myInit)
		.then((data) => {
			this.getUserList()
			.then((data) => {
			  this._isMounted && this.setState({UserList: data});
			})
		})		
	  }



	
getUsers = () => {
	let users = this.state.UserList.map((item, key) =>
	this.getUserView(item,key)
	);
return users
}

getUserView = (e, key) => {
	let username = e.username;
let view = (
	<tr key={e.username}>
      <td>{this.state.UserList[key].email}</td>
      <td>{this.state.UserList[key].email_verified}</td>
      <td>{this.state.UserList[key].UserCreated}</td>
      <td>{this.state.UserList[key].UserState}</td>
	  <td>{this.state.UserList[key].isAdmin}</td>
	  <td>
	  {(e.isAdmin === "Yes") &&
		<Button style={{margin:"2px"}} onClick={() => this.removeUserfromGroup({username})} variant="outline-danger" size='sm' title="Remove admin rights"><FontAwesomeIcon  icon={faUserShield} /></Button>
		}
		{(e.isAdmin === "No") &&
		<Button style={{margin:"2px"}} onClick={() => this.addUsertoGroup({username})} variant="outline-success" size='sm' title="Add admin rights"><FontAwesomeIcon  icon={faUserShield} /></Button>
		}
	  <Button style={{margin:"2px"}}  onClick={() => this.deleteUser({username})} variant="outline-danger" size='sm' title="Delete user"><FontAwesomeIcon  icon={faMinusSquare} /></Button>		  
		</td>
    </tr>
)






return view
}



  render() {
  
  
  
    return (
    		<div style={{marginTop: "15px"}}>
				<Helmet>
          <title>Users - DVI Energi A/S</title>
        </Helmet>
				<Container>
					<Table striped bordered hover>
						<thead>
							<tr>
							<th>Email</th>
							<th>Email confirmed</th>
							<th>User created</th>
							<th>User state</th>
							<th>Is admin</th>
							<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{this.getUsers()}

						</tbody>

					</Table>

				</Container>
				
				
          
          </div>
    )
  }
}

export default UserList;