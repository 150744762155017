
const awsconf = {
        Auth: {

            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            identityPoolId: 'eu-central-1:4aab5b9b-6ec1-4cce-9b91-fbf22f46fd6d',
            
            // REQUIRED - Amazon Cognito Region
            region: 'eu-central-1',

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: 'eu-central-1_kYM1qDnPN',

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: '1oqj70indbsnncatklr6ocpqt4',

            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: false,

            
            // OPTIONAL - customized storage object
            
            
            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            authenticationFlowType: 'USER_SRP_AUTH',

            // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
            

            
        },

        
      
        API: {
            endpoints: [
                {
                    name: "DVIEnergiRESTAPI",
                    endpoint: "https://lab1mrak10.execute-api.eu-central-1.amazonaws.com/prod"
                }
            ]
        }
    





};


export default awsconf;
